import { useRef, useState } from "react";
import "./auth.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";

const Auth = () => {

  const navigate = useNavigate();
  const email = useRef(null);
  const password = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = e => {
    setLoading(true);
    setError(false);
    e.preventDefault();
    e.stopPropagation();
    signInWithEmailAndPassword(auth, email.current.value, password.current.value)
    .then(() => {
      navigate("/");
      setLoading(false);
    })
    .catch(() => {
      setError(true);
      setLoading(false);
    })
  }

  return (
    <div className="auth">
      <div className="authModal">
        <p>WerkAPP Mobile</p>
        <input className="authInput" style={error ? {borderColor: "var(--red)"} : {}} type="email" name="email" id="email" placeholder="E-Mail Adresse" ref={email} />
        <input className="authInput" style={error ? {borderColor: "var(--red)"} : {}} type="password" name="password" id="password" placeholder="Passwort" ref={password} />
        <button className="authBtn" onClick={handleSubmit} disabled={loading}>{ (loading ? "Laden" : "Login")}</button>
      </div>
    </div>
  )
}

export default Auth