import Header from '../header/Header'
import Footer from '../footer/Footer'
import { Route, Routes } from 'react-router-dom';
import "./root.css";
import Auth from '../auth/Auth';
import { auth } from '../../config/firebase';
import { onAuthStateChanged } from "firebase/auth";
import { useState } from 'react';
import Dashboard from '../pages/dashboard/Dashboard';
import Settings from '../pages/settings/Settings';

const UI = () => {

  const [uid, setUid] = useState(null);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUid(user.uid);
    } else {
      setUid(null);
    }
  });

  if(uid) {
    return (
      <div>
        <Header />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/vacations" element={<div>Vacations</div>} />
            <Route path="/illness" element={<div>Illness</div>} />
            <Route path="/hours" element={<div>Hours</div>} />
            <Route path="/tasks" element={<div>Tasks</div>} />
          </Routes>
        <Footer />
      </div>
    )
  }
  else {
    return (<Auth />);
  }
}

export default UI