import { getAuth, getIdTokenResult, signOut } from 'firebase/auth';
import { FaPlaneDeparture, FaRegClock, FaUserCog, FaUserSlash } from "react-icons/fa";
import { BsThermometerHalf } from "react-icons/bs";
//import { MdInstallMobile } from "react-icons/md";
import { IoConstruct } from "react-icons/io5";
import "./dashboard.css";
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Dashboard = () => {
  
  const [clientId, setClientId] = useState("");

  const auth = getAuth();
  const { displayName, uid } = auth.currentUser;
  
  getIdTokenResult(auth.currentUser)
  .then((result) => {setClientId(result?.clientId || "");})
  .catch(() => { setClientId("");});

  const handleSignOut = () => {
    signOut(auth);
  } 

  const getGreeting = () => {
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      return 'Guten Morgen';
    } else if (currentTime >= 12 && currentTime < 18) {
      return 'Hallo';
    } else {
      return 'Guten Abend';
    }
  };

  const greeting = getGreeting();

  return (
    <div>
      <h1>{ greeting }<br/><span>{ displayName }</span></h1>
      <div className="dashboard">
        <Link to="/vacations" className="dashboardBtn">
          <FaPlaneDeparture />
          <div className="btn">Urlaub</div>
        </Link>
        <Link to="/illness" className="dashboardBtn">
          <BsThermometerHalf />
          <div className="btn">Krankheit</div>
        </Link>
        <Link to="/tasks" className="dashboardBtn">
          <IoConstruct />
          <div className="btn">Aufgaben</div>
        </Link>
        <Link to="/hours" className="dashboardBtn">
          <FaRegClock />
          <div className="btn">Zeiterfassung</div>
        </Link>
        <Link to="/settings" className="dashboardBtn">
          <FaUserCog />
          <div className="btn">Einstellungen</div>
        </Link>
        { /* <div className="dashboardBtn" onClick={promptUserToAddToHomeScreen}>
          <MdInstallMobile />
          App hinzufügen
  </div> */ }
      </div>
        <div className="dashboardBtn signOut" onClick={handleSignOut}>
          <FaUserSlash />
          Beenden
        </div>
      <p className="dashboardFooter">UserId: {uid}<br/>
      Server: SSL {clientId} / Europe<br/>
      2021 - {new Date().getFullYear()} WerkAPP Mobile Version {process.env.REACT_APP_VERSION}</p>
    </div>
  )
}

export default Dashboard