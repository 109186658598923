import { NavLink } from "react-router-dom";
import { FaPlaneDeparture, FaRegClock } from "react-icons/fa";
import { BsThermometerHalf } from "react-icons/bs";
import { IoConstruct } from "react-icons/io5";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <NavLink to="/vacations" className={({isActive}) => (isActive ? "footerBtn active" : "footerBtn")}><FaPlaneDeparture /></NavLink>
      <NavLink to="/illness" className={({isActive}) => (isActive ? "footerBtn active" : "footerBtn")}><BsThermometerHalf /></NavLink>
      <NavLink to="/tasks" className={({isActive}) => (isActive ? "footerBtn active" : "footerBtn")}><IoConstruct /></NavLink>
      <NavLink to="/hours" className={({isActive}) => (isActive ? "footerBtn active" : "footerBtn")}><FaRegClock /></NavLink>
    </div>
  )
}

export default Footer