import { getAuth, signOut, updatePassword, updateProfile } from 'firebase/auth'
import React, { useState } from 'react'
import { auth } from '../../../config/firebase'

const Settings = () => {

  const {displayName} = auth.currentUser;

  const [name, setName] = useState(displayName);
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [repeatError, setRepeatError] = useState(false);

  const handleSaveName = () => {
    updateProfile(auth.currentUser, {
      displayName: name
    });
  }

  const handleSavePassword = () => {
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/;
    setRepeatError(repeat !== password);
    if(password.length < 8) {
      setPasswordError("Passwort muss mindestens 8 Zeichen lang sein");
      return;
    }
    else if(password.includes("123") || password.includes("abc") || password.includes("ABC") || password.includes("012") || password.includes("qwertz") || password.includes("asdfg") ) {
      setPasswordError("Passwort ist einfach zu erraten.");
      return;
    }
    else if(!regex.test(password)) {
      setPasswordError("Passwort ist nicht sicher");
      return;
    }
    else if(repeat !== password) {
      return;
    }
    updatePassword(auth.currentUser, password)
    .then(() => {
      setPassword("");
      setPasswordError("");
      setRepeat("");
      setRepeatError(false);
    })
    .catch(() => {
      setPasswordError("Etwas ist schiefgelaufen.");
    });
  }

  const handleSignOut = () => {
    signOut(getAuth());
  }

  return (
    <div>
      <h1>Einstellungen</h1>
      Vor- und Nachname ändern:
      <input className="form-control" name="name" value={name} onChange={e => setName(e.target.value)} placeholder="Vorname Nachname" />
      <div className="btn" style={{marginBottom:"50px"}} onClick={handleSaveName}>Name speichern</div>
      Passwort ändern:
      <input className="form-control" style={(passwordError !== "") ? {borderColor: "var(--red)"}: {}} type={showPassword ? "text" : "password" } name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
      { (passwordError !== "") && <p className="inputError">{passwordError}</p> }
      <input className="form-control" style={repeatError ? {borderColor: "var(--red)"}: {}} type={showPassword ? "text" : "password" } name="repeat" id="repeat" value={repeat} onChange={e => setRepeat(e.target.value)} />
      { repeatError && <p className="inputError">Passwort und Wiederholung stimmen nicht überein</p> }
      <p style={{color: "var(--blue)"}} onClick={() => setShowPassword(!showPassword)} >Passwort { showPassword ? "verbergen" : "anzeigen"}</p>
      <div className="btn" style={{marginBottom:"50px"}} onClick={handleSavePassword}>Passwort speichern</div>
      WerkAPP beenden:
      <div className="btn btnLogout" onClick={handleSignOut}>Beenden</div>
    </div>
  )
}

export default Settings