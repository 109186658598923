import { Link } from "react-router-dom";
import logo from "../../assets/werkapp.svg";
import { auth } from "../../config/firebase";
import "./header.css";

const Header = () => {

  const {displayName} = auth.currentUser;

  return (
    <div className="header">
      <Link to="/" className="headerLogo">
        <img src={logo} alt="logo" />
      </Link>
      <Link to="/settings" className="headerUser">
        { displayName }
      </Link>
    </div>
  )
}

export default Header